//framework
import { DAppModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { MaczkiAPI } from "../classes/MaczkiAPI";

export const ModuleEvents =
{

};

export class Module_MaczkiAPI extends DAppModule
{
    static moduleName = "maczki_api";
    constructor()
    {
        super(m => m.api);
        this.api = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.api === null)
        {
            return;
        }
    }

    async onLoad(dApp)
    {
        if (!Module_MaczkiAPI.availableOnChain(dApp.currentChain))
        {
            return;
        }

        dApp.log(false, `Loading API`);
        this.api = MaczkiAPI.getInstance(dApp);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        return true;
    }
}

export const useMaczkiAPI = () => MaczkiAPI.getInstance();

const currentModule = new Module_MaczkiAPI();
export default currentModule;