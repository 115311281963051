import { configUI as configUI_sdk } from "@MoonLabsDev/dapp-sdk-lib";
import { configUI as configUI_widget } from "@MoonVault/dapp-widgets-lib";

//template & theme
import { Template_Maczki } from "../../templates/Maczki";
import { Theme_Maczki } from "../../themes/Maczki";

const configUI =
{
	...configUI_sdk,

    template: Template_Maczki,
	theme: Theme_Maczki
};

export default configUI;