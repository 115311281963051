//components
import
{
	Flex,
	Grid
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { cx } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "../Components.module.css";

export const MVPage = ({header, children}) =>
{
	return (
		<Flex
			className={cx([{[styles.page_noHeader]: !header}])}
			style=
			{
				{
					width: "100%",
					marginBottom: "25px"
				}
			}
		>
			<Grid
				style=
				{
					{
						maxWidth: "1000px",
						flexGrow: 1
					}
				}
			>
				{header}
				{children}
			</Grid>
		</Flex>
	);
};