//components
import
{
	Text,
	SocialButton,
	SocialPlatform,
	Flex,
	Grid,
	Image
} from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "../Components.module.css";

export const MVPageFooter = () =>
{
	return (
		<Grid
			className={styles.footer}
			gap="20px"
		>
			<Flex>
				<Image
					className={styles.footer_image}
					src="/assets/page/footer_logo.png"
				/>
			</Flex>
			<Grid gap="0px">
				<Text
					color={1}
					size={2}
					align="center"
				>
					Follow us!
				</Text>
				<Text
					color={3}
					size={-1}
					align="center"
				>
					To receive the latest updates and find out about future releases.
				</Text>
			</Grid>
			<Flex gap="20px">
				<SocialButton social={SocialPlatform.Telegram} />
				<SocialButton social={SocialPlatform.Twitter} />
			</Flex>
		</Grid>
	);
};