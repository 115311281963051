import { useMemo, useState } from "react";
import { ResponsiveContainer } from "recharts";

//components
import
{
    Text,
    Button,
    Grid,
    Flex,
	Image,
    Card,
    CardBody,
	TextToggle,
	InputTokenAmount_Balance,
	CheckTransactionButton_Balance,
	Link
} from "@MoonLabsDev/dapp-core-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule,
	SectionHeader
} from "../../dApp/components";
import { LineChart_MaczkiToken, MaczkiTokenMetric } from "src/dApp/components/modules/API";

//framework
import { Translator as t, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMaczkiToken, ModuleEvents_MaczkiToken } from "../../dApp/modules";
import { MaczkiTokenPriceType } from "../../dApp/classes/MaczkiToken";

//styles
import styles from "src/pages/Pages.module.css";

const UtilityItem = ({title, image, link, children, external}) =>
{
    return (
		<Card style={{ height: "100%"}}>
			<CardBody>
				<Flex
					direction="column"
					style={{ height: "100%"}}
				>
					<Image
						style={{ height: "100px" }}
						src={image}
					/>

					<Grid gap="0px">
						<Text
							color={3}
							size={1}
							align="center"
						>
							{title}
						</Text>

						<Text
							color={1}
							size={-1}
							align="center"
						>
							{children}
						</Text>
					</Grid>

					{link &&
						<Button
							style={{ marginTop: "auto"}}
							href={link}
							nav={!external}
						>
							EXPLORE
						</Button>
					}
				</Flex>
			</CardBody>
		</Card>
	);
};

const Utility = () =>
{
    return (
		<Grid
			cols={1}
			responsive=
			{
				{
					sm:
					{
						cols: 3
					}
				}
			}
		>
			<UtilityItem
				title={t("pages.token.utility.1.title")}
				image="/assets/page/earn/token/example-section-1.png"
				link="https://t.me/Maczki"
				external={true}
			>
				{t("pages.token.utility.1.text")}
			</UtilityItem>

			<UtilityItem
				title={t("pages.token.utility.2.title")}
				image="/assets/page/earn/token/example-section-2.png"
				link="/earn/pool"
			>
				{t("pages.token.utility.2.text")}
			</UtilityItem>

			<UtilityItem
				title={t("pages.token.utility.3.title")}
				image="/assets/page/earn/token/example-section-3.png"
			>
				{t("pages.token.utility.3.text")}
			</UtilityItem>
		</Grid>
	);
};

const Stat = (props) =>
{
    return (
        <Flex className={styles.earnToken_stats}>
            <Text size={-1}>
                {props.name}
            </Text>
            <Text size={-1}>
                {props.value}
            </Text>
        </Flex>
    );
};

const Buy = ({data}) =>
{
	//context
    const dApp = useDApp();
	const maczki = useMaczkiToken();

	//state
	const [amountIn, setAmountIn] = useState(() => "");
	const [amountOut, setAmountOut] = useState(() => "");
	const amount = useMemo(() => MLWeb3.convertFloatString_TokenBN(amountIn, maczki.peggedToken), [amountIn])

	//handler
	const handleAmountOutChanged = (amount) =>
	{
		const outVal = MLWeb3.convertFloatString_TokenBN(amount, maczki.token);
		const inVal = maczki.getPriceUSDForAmount(outVal, MaczkiTokenPriceType.BUY);
		setAmountIn(MLWeb3.convertTokenBN_FloatString(inVal, maczki.peggedToken, true));
		setAmountOut(amount);
	};
	const handleBuy = () =>
	{
		maczki.buy(MLWeb3.convertFloatString_TokenBN(amountOut, maczki.token)).trySend();
	}

	return (
		<Card>
			<CardBody>
				<Grid>
					<Text size={0}>
						Buy{" "}
						<Text color={3}>
							{maczki.token?.symbol}
						</Text>
					</Text>

					<InputTokenAmount_Balance
						style={{ width: "100%" }}
                        token={maczki.token}
						value={amountOut}
						showAvailable={true}
                        showMax={false}
						showUSD={false}
						onChange={(e, v) => handleAmountOutChanged(v)}
                    />

					<Text size={0}>
						Pay{" "}
						<Text color={3}>
							{maczki.peggedToken?.symbol}
						</Text>
					</Text>

					<InputTokenAmount_Balance
						style={{ width: "100%" }}
                        token={maczki.peggedToken}
						value={amountIn}
						showAvailable={true}
                        showMax={false}
						showUSD={false}
						placeholder="???"
						readOnly={true}
                    />

                    <CheckTransactionButton_Balance
                        className={styles.earnToken_button}
						token={maczki.peggedToken}
						amount={amount}
						approveFor={maczki.address}
						onClick={() => handleBuy()}
                    >
                        Buy
                    </CheckTransactionButton_Balance>

					<Stat
						name={"Buy Price"}
						value={dApp.formatFiat(data.sellPrice, 8)}
					/>
                </Grid>
			</CardBody>
		</Card>
	);
};

const Sell = ({data}) =>
{
	//context
    const dApp = useDApp();
	const maczki = useMaczkiToken();

	//state
	const [amountIn, setAmountIn] = useState(() => "");
	const [amountOut, setAmountOut] = useState(() => "");
	const amount = useMemo(() => MLWeb3.convertFloatString_TokenBN(amountIn, maczki.token), [amountIn]);

	//handler
	const handleAmountInChanged = (amount) =>
	{
		debugger;
		const inVal = MLWeb3.convertFloatString_TokenBN(amount, maczki.token);
		const outVal = maczki.getPriceUSDForAmount(inVal, MaczkiTokenPriceType.SELL);
		setAmountOut(MLWeb3.convertTokenBN_FloatString(outVal, maczki.peggedToken, true));
		setAmountIn(amount);
	};
	const handleSell = () =>
	{
		maczki.sell(MLWeb3.convertFloatString_TokenBN(amountIn, maczki.token)).trySend();
	};

	return (
		<Card>
			<CardBody>
				<Grid>
					<Text size={0}>
						Redeem{" "}
						<Text color={3}>
							{maczki.token?.symbol}
						</Text>
					</Text>

					<InputTokenAmount_Balance
						style={{ width: "100%" }}
                        token={maczki.token}
						value={amountIn}
						showAvailable={true}
                        showMax={true}
						onChange={(e, v) => handleAmountInChanged(v)}
                    />

					<Text size={0}>
						Receive{" "}
						<Text color={3}>
							{maczki.peggedToken?.symbol}
						</Text>
					</Text>

					<InputTokenAmount_Balance
						style={{ width: "100%" }}
                        token={maczki.peggedToken}
						value={amountOut}
						showAvailable={true}
                        showMax={true}
						placeholder="???"
						readOnly={true}
                    />

                    <CheckTransactionButton_Balance
                        className={styles.earnToken_button}
						token={maczki.token}
						amount={amount}
						approveFor={maczki.address}
						onClick={() => handleSell()}
                    >
                        Redeem
                    </CheckTransactionButton_Balance>

                    <Stat
						name={"Sell Price"}
						value={dApp.formatFiat(data.sellPrice, 8)}
					/>
                </Grid>
			</CardBody>
		</Card>
	);
};

const BuySell = () =>
{
    //context
    const maczki = useMaczkiToken();

    //function
    const getMooonTokenValues = () =>
    {
        return {
            buyPrice: maczki.tokenPrice,
            sellPrice: maczki.tokenValue,
            availableSupply: maczki.availableSupply,
            circulatingSupply: maczki.circulatingSupply
        };
    }

    //state
    const [data, setData] = useState(() => getMooonTokenValues());

    //effects
    useEventSubscription(ModuleEvents_MaczkiToken.data, () => setData(getMooonTokenValues()));

    return (
        <Grid
			cols={1}
			responsive=
			{
				{
					md:
					{
						cols: 2
					}
				}
			}
		>
			<Buy data={data} />

			<Sell data={data} />
        </Grid>
    );
};

const Warning = () =>
{
    return (
		<Card>
			<CardBody className={styles.earnToken_warning}>
				<Grid gap="0px">
					<Flex>
						<Text size={1}>
							{t("pages.token.intro.header")}
						</Text>
					</Flex>
					<Flex>
						<Text
							style={{ color: "#000" }}
							align="center"
						>
							{t("pages.token.intro.text")}
						</Text>
					</Flex>
				</Grid>
			</CardBody>
		</Card>
    );
};

const PriceChart = () =>
{
	//const
	const info =
	[
		{ text: "Price", showInfo: MaczkiTokenMetric.UNIT_PRICE },
		{ text: "Supply", showInfo: MaczkiTokenMetric.CIRUCLATING_SUPPLY },
		{ text: "Pegged", showInfo: MaczkiTokenMetric.PEGGED_BALANCE },
		{ text: "All", showInfo: MaczkiTokenMetric.ALL }
	];

	//state
	const [showInfo, setShowInfo] = useState(() => MaczkiTokenMetric.UNIT_PRICE);

	return (
		<Card>
			<CardBody>
				<Grid>
					<Flex
						justify="space-between"
						style={{ height: "42px" }}
					>
						<Text>
							MACZKI Price:
						</Text>

						<TextToggle
							values={info}
							selectedIndex={info.indexOf(info.find(i => i.showInfo === showInfo))}
							onChange={(v, i) => setShowInfo(v.showInfo)}
						/>
					</Flex>
					<div style={{ width: "100%", height: 300 }}>
						<ResponsiveContainer>
							<LineChart_MaczkiToken
								width={950}
								height={300}
								metrics={showInfo}
							/>
						</ResponsiveContainer>
					</div>
				</Grid>
			</CardBody>
		</Card>
	);
};

const MaczkiTokenContent = () =>
{
    return (
		<Flex>
			<Grid>
				<Warning />

				<MVCheckModule module="maczki_token">
					<BuySell />
				</MVCheckModule>

				<PriceChart />

				<SectionHeader>
					Utility
				</SectionHeader>
				<Utility />

			</Grid>
		</Flex>
    );
};

export const Page_earn_token = () =>
{
    return (
        <MVPage
			header=
			{
				<MVPageHeader
					title=""
					backgroundImage="url(/assets/page/earn/token/header.png)"
				/>
			}
		>
            <MaczkiTokenContent />
        </MVPage>
    );
}