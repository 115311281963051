import configPage from "./page";

//components
import { ToolMenu } from "../components/ToolMenu";

const generateTestMenu = () =>
{
	if (!configPage.devMode)
	{
		return [];
	}

	return [
		{
			text: "Test",
			link: "/tests/general",
			children:
			[
				{
					text: "General",
					link: "/tests/general"
				},
				{
					text: "Extended",
					link: "/tests/extended"
				},
				{
					text: "Cards",
					link: "/tests/cards"
				},
				{
					text: "Charts",
					link: "/tests/charts"
				},
				{
					text: "Modals",
					link: "/tests/modals"
				}
			]
		}
	]
};

const configMenu =
{
	toolComponent: ToolMenu,
    desktopMode:
    {
        header:
        {
            menu: "normal",
			tool: "normal",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    tabletMode:
    {
        header:
        {
            menu: "minimal",
			tool: "none",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    mobileMode:
    {
        header:
        {
            menu: "none",
			tool: "none",
            user: "app"
        },
        footer:
        {
            menu: "app",
			tool: "none",
            user: "none"
        }
    },

	desktopMenu:
	[
		{
			label: "Home",
			link: "/"
		},
		{
			text: "Token",
			link: "/token",
		},
		{
			text: "Swap",
			link: "/swap",
		},
		{
			text: "Info",
			children:
			[
				{
					text: "Whitepaper",
					link: "/assets/page/Whitepaper.pdf",
					extern: true
				},
				{
					text: "Roadmap",
					link: "/assets/page/Roadmap.jpg",
					extern: true
				}
			]
		},
		...generateTestMenu()
	],

	tabletMenu: undefined, //will use desktop instead
	mobileMenu: undefined, //will use tablet instead
};

export default configMenu;