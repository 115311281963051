//components
import { Text, Grid } from "@MoonLabsDev/dapp-core-lib";
import
{
    MoonVaultWidgets,
    PageWrapper_MoonSwap
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule
} from "../../dApp/components";

export const Page_swap = () =>
{
	return (
		<MVPage
			header=
			{
				<MVPageHeader
					title=""
					backgroundImage="url(/assets/page/swap/header.png)"
				>

				</MVPageHeader>
			}
		>
			<Grid>
				<Text
					color={0}
					size={2}
					align="center"
				>
					Maczki Swap
				</Text>
				<Text align="center">
					Find the cheapest swap across multiple decentralized exchanges
				</Text>
			</Grid>
            <MVCheckModule module={MoonVaultWidgets.swap}>
				<PageWrapper_MoonSwap />
			</MVCheckModule>
        </MVPage>
    );
}