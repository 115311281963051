import { useState } from "react";

//components
import { ToolBar } from "@MoonLabsDev/dapp-sdk-lib";
import { MVPageFooter } from "src/dApp/components";

//styles
import stylesTemplate from "./Template.module.css";

export const Template_Maczki = (props) =>
{
	//state
	const [hasHeader, setHasHeader] = useState(() => true);
	const [hasFooter, setHasFooter] = useState(() => true);

    return (
        <div>
            <header className={stylesTemplate.header}>
                <ToolBar
					header={true}
					hasContentCallback={setHasHeader}
				/>
            </header>
            <main
				className={stylesTemplate.main}
				style=
				{
					{
						paddingTop: (hasHeader
							? "var(--ML_toolBar_height)"
							: "0px"
						),
						paddingBottom: (hasFooter
							? "var(--ML_toolBar_height)"
							: "0px"
						)
					}
				}
			>
                {props.children}
				<MVPageFooter />
            </main>
            <footer className={stylesTemplate.footer}>
                <ToolBar
					header={false}
					hasContentCallback={setHasFooter}
				/>
            </footer>
        </div>
    );
};