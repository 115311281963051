//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { MaczkiToken } from "../classes/MaczkiToken";

export const ModuleEvents =
{
    init: "maczkiToken_init",
    data: "maczkiToken_data"
};

export class Module_MaczkiToken extends DAppModule
{
    static moduleName = "maczki_token";
    constructor()
    {
        super(m => m.maczkiToken);
        this.maczkiToken = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.maczkiToken === null)
        {
            return;
        }

        //data
        await MLUtils.measureTime(`TM: MaczkiToken => Data`, async () =>
        {
            await this.maczkiToken.reload_data();
        });
    }

    async onLoad(dApp)
    {
        if (!Module_MaczkiToken.availableOnChain(dApp.currentChain))
        {
            return;
        }

        const addr = dApp.currentChain?.modules?.maczkiChef;
        dApp.log(false, `Loading MaczkiToken [${addr}]`);
        this.maczkiToken = new MaczkiToken(dApp, addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.maczkiChef;
        return (addr !== undefined
            && addr !== "");
    }
}

export const useMaczkiToken = () => useModule(Module_MaczkiToken);

const currentModule = new Module_MaczkiToken();
export default currentModule;