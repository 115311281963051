import ThemeCSS from "./Theme.module.css";

export const Theme_Maczki =
{
	css: ThemeCSS,
	vars:
	{
		background:
		{
			image: `url(/assets/page/background.png)`,
			position: "center",
			//attachment: "fixed",
			size: "1400px",
			repeat: "repeat"
		},
		selectChain:
		{
			useSelectBox: true,
			color: 1,
			chainColor: 1
		},
		connectWallet:
		{
			color: 0,
			walletColor: 1
		},
		gasPrice:
		{
			color: 1
		},
		tokenPrice:
		{
			color: 1
		},
		selectToken:
		{
			quickColor: 1,
			walletColor: 1
		}
	}
};