//components
import
{
	Card,
	CardBody,
	ErrorServiceNotAtChain,
	InfoModuleLoading
} from "@MoonLabsDev/dapp-core-lib";

const Wrapper = ({children}) =>
{
	return (
		<Card>
			<CardBody>
				{children}
			</CardBody>
		</Card>
	);
}

export const MVCheckModule = ({module, children}) =>
{
	return (
		<ErrorServiceNotAtChain
			module={module}
			wrapperComponent={Wrapper}
		>
			<InfoModuleLoading
				module={module}
				wrapperComponent={Wrapper}
			>
				{children}
			</InfoModuleLoading>
		</ErrorServiceNotAtChain>
	);
};