//framework
import { DApp, MLWeb3, MLMultiCall, MLFormat, Web3Transaction } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_MaczkiToken";

//contracts
import ABI_MaczkiToken from "../abi/MaczkiChef";

export const MaczkiTokenPriceType =
{
	LOSS: -1,
	BUY: 0,
	SELL: 1
};

export class MaczkiToken
{
	////////////////////////////////////

	constructor(dapp, _address)
	{
		//init
		this.initialized = false;
        this.dapp = dapp;

		//values
		this.address = _address;
		this.percentFactor = 1000000;

		//data
		this.token = null;
		this.peggedToken = null;
		this.tokenAddress = "";
		this.peggedTokenAddress = "";
		this.minBuyPrice = MLWeb3.toBN(0);
		this.buyPriceMultiplicator = 0;
		this.buyPriceFee = 0;
		this.sellPriceFee = 0;
		this.feeAddress = "";
		this.totalMinted = MLWeb3.toBN(0);
		this.totalSupply = MLWeb3.toBN(0);
		this.burnedSupply = MLWeb3.toBN(0);
		this.circulatingSupply = MLWeb3.toBN(0);
		this.burnedSupply = MLWeb3.toBN(0);
		this.totalBalance = MLWeb3.toBN(0);
		this.tokenValue = MLWeb3.toBN(0);
		this.tokenPrice = MLWeb3.toBN(0);
		this.wrappedBalance = MLWeb3.toBN(0);
		this.wrappedBalanceUSD = MLWeb3.toBN(0);
		this.valueLoss = 0;
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `MaczkiToken failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_MaczkiToken, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_MaczkiToken,
            _calls
        );
    }

    dispatchEvent(_name)
    {
        document.dispatchEvent(new CustomEvent(_name));
    }

	/////////////////////////
    // Init
    /////////////////////////

    async init()
    {
        if (this.initialized)
        {
            return;
        }
        await this.dapp.batchCall(
            [this],
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[MaczkiToken] init",
            "MaczkiToken: init"
        );
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
            percentFactor: { function: "PERCENT_FACTOR" },
            tokenAddress: { function: "maczkiToken" },
            peggedTokenAddress: { function: "peggedToken" },
            minBuyPrice: { function: "minBuyPrice" },
            feeAddress: { function: "feeAddress" },
            buyPriceFee: { function: "buyPriceFee" },
            buyPriceMultiplicator: { function: "buyPriceMultiplicator" },
			sellPriceFee: { function: "sellPriceFee" },
        });
    }

    async processRequest_init(_data)
    {
        this.percentFactor			= parseInt(_data.percentFactor);
		this.tokenAddress 		    = _data.tokenAddress;
		this.peggedTokenAddress 	= _data.peggedTokenAddress;
		this.minBuyPrice			= MLWeb3.toBN(_data.minBuyPrice);
		this.buyPriceMultiplicator	= parseFloat(_data.buyPriceMultiplicator) / this.percentFactor;
		this.buyPriceFee			= parseFloat(_data.buyPriceFee) / this.percentFactor;
		this.sellPriceFee			= parseFloat(_data.sellPriceFee) / this.percentFactor;
		this.feeAddress				= _data.feeAddress;

        //process
        this.token = this.dapp.findToken(this.tokenAddress);
        this.peggedToken = this.dapp.findToken(this.peggedTokenAddress);

        //comlete
        this.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.init);
    }

    /////////////////////////
    // Data
    /////////////////////////

    async reload_data()
    {
        await this.init()
		if (!this.initialized)
		{
			return;
		}
        await this.dapp.batchCall(
            [this],
            (o) => o.makeRequest_data(),
            (o, r) => o.processRequest_data(r),
            false,
            "[MaczkiToken] data",
            "MaczkiToken: data"
        );
    }

    makeRequest_data()
    {
        return this.makeMultiCall(
        {
            totalSupply: { function: "totalSupply" },
            circulatingSupply: { function: "circulatingSupply" },
            burnedSupply: { function: "burnedSupply" },
            totalBalance: { function: "totalBalance" },
            wrappedBalance: { function: "wrappedBalance" },
            tokenValue: { function: "tokenValue" },
            tokenPrice: { function: "tokenPrice" },
            totalMinted: { function: "totalMinted" }
        });
    }

    async processRequest_data(_data)
    {
        this.totalSupply			= MLWeb3.toBN(_data.totalSupply);
		this.circulatingSupply 		= MLWeb3.toBN(_data.circulatingSupply);
		this.burnedSupply			= MLWeb3.toBN(_data.burnedSupply);
		this.totalBalance			= MLWeb3.toBN(_data.totalBalance);
		this.wrappedBalance			= MLWeb3.toBN(_data.wrappedBalance);
		this.tokenValue				= MLWeb3.toBN(_data.tokenValue);
		this.tokenPrice				= MLWeb3.toBN(_data.tokenPrice);
		this.totalMinted			= MLWeb3.toBN(_data.totalMinted);

		//process
		this.wrappedBalanceUSD = await this.peggedToken.getPriceUSDForAmount(this.wrappedBalance);
		this.valueLoss = -(1 - MLWeb3.getPercent(this.tokenValue, this.tokenPrice));

        //handle price
        this.token.unitPriceUSD = (this.circulatingSupply.isZero()
			? MLWeb3.toBN(0)
			: this.totalBalance.mul(this.token.one).div(this.circulatingSupply)
		);
		this.token.liquidityValue = this.totalBalance;
		this.token.liquidityAmount = this.circulatingSupply;
		this.token.lastPriceUpdate = new Date();
		this.token.initializedPrice = true;
		this.token.priceUpdated();
		this.dapp.oracle.logUnitPrice(this.token, true);

        //event
        this.dispatchEvent(ModuleEvents.data);
    }

	/////////////////////////
    // Helper
    /////////////////////////

	getPriceUSDForAmount(_amount, _type = MaczkiTokenPriceType.BUY)
	{
		switch (_type)
		{
			case MaczkiTokenPriceType.LOSS:
				return this.tokenPrice.sub(this.tokenValue).mul(_amount).div(this.token.one);

			case MaczkiTokenPriceType.SELL:
				return this.tokenValue.mul(_amount).div(this.token.one);

			default:
				return this.tokenPrice.mul(_amount).div(this.token.one);
		}
	}

	/////////////////////////
    // Transactions
    /////////////////////////

	async checkApproved_Token()
	{
		return await this.token.checkApproved(this.address);
	}

	async checkApproved_Pegged()
	{
		return await this.peggedToken.checkApproved(this.address);
	}

	buy(_amount, _slippage = .01)
    {
		const price = this.getPriceUSDForAmount(_amount, MaczkiTokenPriceType.BUY);
		const maxOut = price.mul(MLWeb3.toBN((1 + _slippage) * this.percentFactor)).div(MLWeb3.toBN(this.percentFactor));

        const con = this.getContract(true);
        return new Web3Transaction(
            con.buy(_amount, maxOut),
            this.debugErrorString("buy"),
            `Buy ${MLFormat.smartFormatToken(_amount, this.token)} ${this.token.symbol}`
		);
    }

	sell(_amount)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.sell(_amount),
            this.debugErrorString("sell"),
            `Sell ${MLFormat.smartFormatToken(_amount, this.token)} ${this.token.symbol}`
		);
    }

	swapForLiquidity(_token)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.swapForLiquidity(_token.address),
            this.debugErrorString("swapForLiquidity"),
            `Swap for Liquidity`
		);
    }
}