//components
import
{
    Text,
    Flex
} from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./Components.module.css";

export const SectionHeader = ({children}) =>
{
	return (
		<Flex className={styles.sectionHeader}>
			<Text size={2}>
				{children}
			</Text>
		</Flex>
	);
};
