import { useState, useEffect, useMemo } from "react";

//components
import { LineChart, formatCustomTooltipData } from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLDB, MLWeb3, MLUtils, MLFormat, DataSeriesFillType } from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useMaczkiAPI } from "../../../modules";

export const MaczkiTokenMetric =
{
    PEGGED_BALANCE: 1,
    CIRUCLATING_SUPPLY: 2,
    UNIT_PRICE: 4,

    ALL: (1 | 2 | 4)
};

export const LineChart_MaczkiToken = (props) =>
{
    //hooks
    const api = useMaczkiAPI();

	//functions
	const formatTooltip = (_label, _payload) =>
	{
		const texts =
		[
			{ id: "PeggedBalance", text: "Pegged Balance" },
			{ id: "CirculatingSupply", text: "Circulating Supply" },
			{ id: "UnitPrice", text: "Value"}
		];

		const data = formatCustomTooltipData(_label, _payload);
		data.title = MLFormat.formatDate(new Date(data.x));
		data.data.forEach(d =>
			{
				const type = d.text;
				d.text = texts.find(t => t.id === d.text).text;

				switch (type)
				{
					case "PeggedBalance":
						d.value = MLFormat.formatFiat(d.value);
						break;

					case "UnitPrice":
						d.value = MLFormat.formatFiat(d.value, 6);
						break;

					case "CirculatingSupply":
						d.value = `${parseInt(d.value)} MACZKI`;
						break;
				}
			}
		);
		return data;
	};

    //state
	const metrics = useMemo(() => props.metrics || MaczkiTokenMetric.ALL, [props.metrics]);
    const [dataValues, setDataValues] = useState(() => []);
    const [dataKeys, setDataKeys] = useState(() => []);
    const [dataColors, setDataColors] = useState(() => []);
    const [domainPerKey, setDomainPerKey] = useState(() => undefined);
	const [dataSets, setDataSets] = useState(() => null);

    //handler
    const handleGetData = async () =>
    {
        if (dataSets === null)
        {
            return;
        }

        //keys
        const allColors = ["var(--MV_palette_chart_color3)", "var(--MV_palette_chart_color2)", "var(--MV_palette_chart_color1)"];
        const allKeys = ["PeggedBalance", "CirculatingSupply", "UnitPrice"];
        const dKeys = [];
        const dCols = [];
        if (metrics & MaczkiTokenMetric.PEGGED_BALANCE)
        {
            dKeys.push(allKeys[0]);
            dCols.push(allColors[0]);
        }
        if (metrics & MaczkiTokenMetric.CIRUCLATING_SUPPLY)
        {
            dKeys.push(allKeys[1]);
            dCols.push(allColors[1]);
        }
		if (metrics & MaczkiTokenMetric.UNIT_PRICE)
        {
            dKeys.push(allKeys[2]);
            dCols.push(allColors[2]);
        }
        setDataKeys(dKeys);
        setDataColors(dCols);

        //get data
        const dVals = [];
        const dDomains = [];
        for (let n = 0; n < dataSets.data.length; n++)
        {
            const dv = dataSets.data[n];
            const v = MLUtils.dataSeries_findOrInsert(
                dVals,
                "x",
                MLUtils.roundDate_Hour(MLDB.convertDateTime(dv.dateTime)).getTime());
            v["PeggedBalance"] = MLWeb3.convertBN_Float(MLWeb3.toBN("0x" + dv.peggedBalance), 6);
            v["CirculatingSupply"] = MLWeb3.convertBN_Float(MLWeb3.toBN("0x" + dv.circulatingSupply), 18);
            v["UnitPrice"] = parseFloat(dv.unitPrice);
        }

        //fill and normalize
        MLUtils.dataSeries_insertSteps(
            dVals,
            "x",
            1000 * 60 * 60 * 24 //1 day
        );
        MLUtils.dataSeries_fillMissing(
            dVals,
            allKeys,
            DataSeriesFillType.FILL,
            DataSeriesFillType.ZERO,
            DataSeriesFillType.FILL
        );
        MLUtils.dataSeries_normalize(
            dVals,
            [
                allKeys[0],
                allKeys[1]
            ],
            (v) => parseInt(parseFloat(v))
        );
        setDataValues(dVals);

        //domains
        if (metrics & MaczkiTokenMetric.PEGGED_BALANCE)
        {
            dDomains.push(
            {
                key: allKeys[0],
                domain:
                [
                    dVals[0][allKeys[0]],
                    dVals[dVals.length - 1][allKeys[0]]
                ]
            });
        }
        if (metrics & MaczkiTokenMetric.CIRUCLATING_SUPPLY)
        {
            dDomains.push(
            {
                key: allKeys[1],
                domain:
                [
                    dVals[0][allKeys[1]],
                    dVals[dVals.length - 1][allKeys[1]]
                ]
            });
        }
        if (metrics & MaczkiTokenMetric.UNIT_PRICE)
        {
            dDomains.push(
            {
                key: allKeys[2],
                domain:
                [
                    dVals[0][allKeys[2]],
                    dVals[dVals.length - 1][allKeys[2]]
                ]
            });
        }
        //setDomainPerKey(dDomains);
    };

    //effects
	useEffect(async() =>
	{
		const data = (await api.maczkiToken_getLiquidityData({ days: 720 }))?.data || [];
		setDataSets(
		{
			data: data
		});
	}, [metrics]);
	useEffect(handleGetData, [dataSets]);

    return (
        <LineChart
			width={props.width || 600}
			height={props.height || 200}
			showAxis={false}
			gridType={-1}
			dot={false}
            colors={dataColors}
            data={dataValues}
            dataKeys={dataKeys}
            domainPerKey={domainPerKey}
			formatTooltip={formatTooltip}
        />
    );
};